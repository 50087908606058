




















































import { ref, defineComponent, toRefs, computed } from '@nuxtjs/composition-api';
import { productGetters } from '@vsf-enterprise/commercetools';
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import type { PropType, ComputedRef } from '@nuxtjs/composition-api';
import {
  useAddToCart,
  useIsMounted,
  useProductPrice,
  useAddToCartQuantity
} from '~/composables';
import Button from '~/components/atoms/Buttons/Button/Button.vue';
import { SCHEMA_ITEM_PROP, SCHEMA_ITEM_TYPE } from '~/constants/seo';
import { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';
import QuantitySelector from '~/components/atoms/Product/QuantitySelector.vue';
import { isTestProduct } from '~/helpers/product/isTestProduct';
import { EVENT_SOURCE } from '~/constants/googleTagManager';

export default defineComponent({
  name: 'AddToCart',
  components: {
    Button,
    QuantitySelector

  },
  props: {
    product: {
      type: Object as PropType<ProductVariant>,
      required: true
    },
    productAdjustedForCart: {
      type: Object as PropType<ProductVariant>,
      default: () => {}
    },
    attributesLocalized: {
      type: Object as PropType<ProductProcessedAttributes>,
      default: () => ({})
    },
    hasQuantitySelector: {
      type: Boolean,
      default: true
    },
    predefinedQuantity: {
      type: Number,
      default: null
    }
  },
  setup(props, { root: { $route } }) {
    const { product, attributesLocalized } = toRefs(props);

    const { slug } = $route.params;
    const initialQuantityToAdd = ref(1);

    const quantityToAdd: ComputedRef<number> = computed(
      () => props.predefinedQuantity || initialQuantityToAdd.value
    );

    const productSku = productGetters.getSku(props.product);
    const { getPriceInformation } = useProductPrice();
    const { productPrice } = getPriceInformation(product, attributesLocalized);
    const {
      availableQuantity,
      isQuantitySelectionPossible,
      addToCartButtonText
    } = useAddToCart(productSku);

    const { addQuantity } = useAddToCartQuantity({
      sku: productSku,
      slug,
      productPrice: productPrice.value,
      isMainProduct: true,
      addToCartText: addToCartButtonText.value,
      isWarrantySection: false
    });

    const onAddQuantity = () => {
      addQuantity(quantityToAdd.value);
      resetQuantity();
    };

    const { isMounted } = useIsMounted();

    const resetQuantity = () => {
      initialQuantityToAdd.value = 1;
    };

    const quantityChanged = (value: number) => {
      initialQuantityToAdd.value = value;
    };

    const schemaAvailabilityLink = computed(() => {
      if (isTestProduct(productSku)) {
        return SCHEMA_ITEM_TYPE.OFFER_PRE_ORDER;
      } else if (isQuantitySelectionPossible.value) {
        return SCHEMA_ITEM_TYPE.OFFER_IN_STOCK;
      } else {
        return SCHEMA_ITEM_TYPE.OFFER_OUT_OF_STOCK;
      }
    });

    return {
      isMounted,
      initialQuantityToAdd,
      availableQuantity,
      onAddQuantity,
      isQuantitySelectionPossible,
      addToCartButtonText,
      SCHEMA_ITEM_PROP,
      SCHEMA_ITEM_TYPE,
      quantityChanged,
      schemaAvailabilityLink,
      productSku,
      EVENT_SOURCE
    };
  }
});

